// external dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// gatsby dependencies
import { Link } from 'gatsby';

const propTypes = {
  icon: PropTypes.bool,
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  blue: PropTypes.bool,
  gold: PropTypes.bool,
  green: PropTypes.bool,
  href: PropTypes.string,
};

const defaultProps = {
  icon: false,
  blue: false,
  gold: false,
  green: false,
  link: '',
  href: '',
};

const StyledButton = styled.button`
  background-color: ${({ buttonColor, theme }) =>
    buttonColor === 'gold'
      ? theme.colors.gold
      : buttonColor === 'blue'
      ? theme.colors.blue
      : buttonColor === 'green'
      ? theme.colors.green
      : theme.colors.gold};
  color: ${(props) => props.theme.colors.white};
  margin: ${(props) => (props.center ? 'auto' : 0)};
  span {
    padding-right: ${(props) => props.icon && '5px'};
  }

  @media (max-width: 800px) {
    margin: auto;
  }
`;

// component definition
export const Button = ({
  children,
  buttonColor,
  icon,
  link,
  center,
  href,
  ...other
}) => {
  return href ? (
    <a
      href={href}
      rel='noopener'
      rel='noreferrer'
      style={{ textDecoration: 'none' }}
    >
      <StyledButton center={center} buttonColor={buttonColor} {...other}>
        <span>{children}</span>
        {icon && <ArrowForwardIcon />}
      </StyledButton>
    </a>
  ) : (
    <Link to={link || '#'} style={{ textDecoration: 'none' }}>
      <StyledButton center={center} buttonColor={buttonColor} {...other}>
        <span>{children}</span>
        {icon && <ArrowForwardIcon />}
      </StyledButton>
    </Link>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
