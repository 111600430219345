// external Dependencies
import React, { useState } from 'react';
import styled from 'styled-components';

// gatsby Dependencies
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';

// internal Dependencies
import Button from './Button';
import Logo from '../../assets/logo.svg';

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  height: 100px;
  box-shadow: ${(props) => props.theme.effects.boxShadow};

  div#mobile {
    display: none;
    width: 20px;
    position: relative;
    height: 100%;
    cursor: pointer;
    span {
      position: absolute;
      width: 20px;
      height: 4px;
      background-color: black;
    }

    span:nth-child(1) {
      transform: translateY(-8px);
      transition: ${(props) => props.theme.effects.transition};
    }

    span:nth-child(2) {
      transform: translateY(0px);
    }

    span:nth-child(3) {
      transform: translateY(8px);
      transition: ${(props) => props.theme.effects.transition};
    }

    &:focus {
      outline: none;
      span {
        background-color: red;
      }
    }

    @media (max-width: 1000px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    ul#menu {
      display: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-items: center;
    text-align: right;
    font-size: 0.9rem;
    font-family: 'proxima-nova-regular';
  }

  li {
    position: relative;
    padding: 25px 8px;

    &:hover {
      ul {
        visibility: visible;
        display: block;
      }
    }

    ul {
      visibility: hidden;
      position: absolute;
      top: 60px;
      background: ${(props) => props.theme.colors.white};
      border-radius: 4px;
      text-align: left;
      padding: 10px 15px;
      box-shadow: ${(props) => props.theme.effects.boxShadow};
      min-width: 250px;
      z-index: 2;

      li {
        padding: 5px 0;
        text-align: left;
      }
    }

    a {
      text-transform: capitalize;
      display: flex;
      align-items: center;
      text-decoration: none;
    }
  }

  svg#logo {
    width: 200px;
    height: auto;
  }
`;

const StyledMobileNavWrapper = styled.div`
  display: none;

  ul#mobile-nav {
    display: none;
  }

  @media (max-width: 1000px) {
    position: absolute;
    display: block;
    top: 100px;
    right: 0;
    padding: 15px 24px;
    width: 50%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 100;
    height: 100vh;
    overflow-y: auto;

    ul#mobile-nav {
      display: block;
      flex-direction: column;
      text-align: right;
      list-style: none;
      align-items: flex-end;
      position: relative;

      li#mobile-cta-button {
        margin-top: 25px;

        a {
          button {
            margin: 0;
          }
        }
      }

      li {
        z-index: 1;
        position: relative;
        padding: 10px 0;
        text-align: right;

        a {
          font-size: 1.25rem;
        }

        ul {
          display: block;
          visibility: visible;
          box-shadow: none;
          position: relative;
          top: unset;
          background: transparent;
          border-radius: 0;
          padding: 10px 0px;
          min-width: unset;

          li {
            a {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              width: 90%;
              text-align: left;
              font-size: 1rem;
              text-overflow: ellipsis;
            }
          }
        }

        &:hover {
          ul {
            margin-bottom: 10px;
            margin-top: 10px;
            position: relative;
            display: block;
            top: unset;
            padding: unset;
            box-shadow: none;
          }
        }
      }
      li:last-child {
        padding: 10px 0 0 0;
      }
    }
  }

  @media (max-width: 600px) {
    width: 75%;
  }
`;

// component definition
const Nav = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allSite {
        edges {
          node {
            siteMetadata {
              title
              menuItems {
                link
                name
                children {
                  link
                  name
                }
              }
            }
          }
        }
      }
      info: allContentfulProductInformationPageTemplate {
        nodes {
          slug
          pageTitle
          menuTitle
        }
      }
      video: allContentfulVideoPageTemplate {
        nodes {
          slug
          pageTitle
          menuTitle
        }
      }
    }
  `);

  const concoraPages = data.allSite.edges[0].node.siteMetadata.menuItems.map(
    (item, i) => {
      if (item.name.includes('products')) {
        return item.children.map((listItem) => (
          <li
            key={`${i}-${listItem.name}`}
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            <a target='_blank' rel='noreferrer noopener' href={listItem.link}>
              {listItem.name}
            </a>
          </li>
        ));
      }
    }
  );

  const productInfoPages = data.info.nodes.map((item, i) => {
    return (
      <li key={i} onClick={() => setShowMobileNav(!showMobileNav)}>
        <Link
          to={`/precast-concrete-walls-fences-product-information/${item.slug}/`}
        >
          {item.menuTitle}
        </Link>
      </li>
    );
  });

  const mobileNavMenu = data.allSite.edges[0].node.siteMetadata.menuItems.map((item, i ) => {
    return (
      <li key={i} onClick={() => setShowMobileNav(!showMobileNav)}>
        <Link to={`${item.link}`}>
          {item.name}
        </Link>
      </li>
    )
  });

  const menuItems = data.allSite.edges[0].node.siteMetadata.menuItems.map(
    (item, i) => {
      return (
        <li key={i} onClick={() => setShowMobileNav(!showMobileNav)}>
          <Link to={`${item.link}`}>
            {item.name}
            {(item.children ||
              item.name.includes('services') ||
              item.name.includes('who you are')) && (
              <div
                style={{
                  fontSize: '1.8rem',
                  transform: 'translateY(1px) rotate(90deg)',
                  marginLeft: '3px',
                }}
              >
                &#8227;
              </div>
            )}
          </Link>
          {item.children && (
            <ul>
              {item.name.includes('product information') && (
                <>
                  {productInfoPages}
                  {item.children && (
                    <>
                      {item.children.map((child, j) => {
                        return (
                          <li
                            key={j}
                            onClick={() => setShowMobileNav(!showMobileNav)}
                          >
                            <Link to={`${child.link}/`}>{child.name}</Link>
                          </li>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </ul>
          )}
          {item.children && (
            <>
              {item.name.includes('videos') && (
                <ul>
                  {item.children && (
                    <>
                      {item.children.map((child, j) => {
                        return (
                          <li
                            key={j}
                            onClick={() => setShowMobileNav(!showMobileNav)}
                          >
                            <Link to={`${child.link}/`}>{child.name}</Link>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              )}
            </>
          )}
          {item.name.includes('products') && <ul>{concoraPages}</ul>}
          {item.name.includes('services') && (
            <ul>
              {item.children && (
                <>
                  {item.children.map((child, j) => {
                    return (
                      <li
                        key={j}
                        onClick={() => setShowMobileNav(!showMobileNav)}
                      >
                        <Link to={`${child.link}/`}>{child.name}</Link>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          )}
          {item.name.includes('who you are') && (
            <ul>
              {item.children && (
                <>
                  {item.children.map((child, j) => {
                    return (
                      <li
                        key={j}
                        onClick={() => setShowMobileNav(!showMobileNav)}
                      >
                        <Link to={`${child.link}/`}>{child.name}</Link>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          )}
        </li>
      );
    }
  );

  return (
    <StyledNav>
      <Link to='/'>
        <Logo />
      </Link>
      <ul id='menu'>
        {menuItems}
        <li>
          <Button gold icon link='/contact/'>
            Contact Us
          </Button>
        </li>
      </ul>
      {showMobileNav && (
        <StyledMobileNavWrapper>
          <ul id='mobile-nav'>
            {mobileNavMenu}{' '}
            <li id='mobile-cta-button'>
              <Button gold icon link='/contact/'>
                Contact Us
              </Button>
            </li>
          </ul>
        </StyledMobileNavWrapper>
      )}

      <div id='mobile' onClick={() => setShowMobileNav(!showMobileNav)}>
        <span
          style={{
            transform: showMobileNav
              ? 'translateY(0px) rotate(45deg)'
              : 'translateY(-8px)',
          }}
        ></span>
        <span
          style={{
            visibility: showMobileNav ? 'hidden' : 'visible',
          }}
        ></span>
        <span
          style={{
            transform: showMobileNav
              ? 'translateY(0px) rotate(-45deg)'
              : 'translateY(8px)',
          }}
        ></span>
      </div>
    </StyledNav>
  );
};

export default Nav;
