import './root.css';

const theme = {
  colors: {
    white: `var(--white)`,
    black: `var(--black:)`,
    darkGray: `var(--dark-gray)`,
    /* brand colors */
    gold: `var(--scp-gold)`,
    blue: `var(--scp-blue)`,
    green: `var(--scp-green)`,
    lightEcru: `var(--scp-light-ecru)`,
    ecru: `var(--scp-ecru)`,
    lightGray: `var(--scp-light-gray)`,
    warmGray: `var(--scp-warm-gray)`,
  },
  effects: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2);',
    transition: 'all 0.3s ease',
  },
  elements: {
    borderRadius: '4px',
  },
};

export default theme;
