// external dependencies
import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

// internal dependencies
import Logo from '../../assets/logo.svg';

const StyledFooter = styled.footer`
  min-height: 100px;
  padding: 8px 24px;
  background: ${(props) => props.theme.colors.blue};
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  svg {
    width: 12em;
    height: auto;

    .cls-1 {
      fill: white;
    }
  }

  p {
    text-align: center;
    color: ${(props) => props.theme.colors.white};
    font-family: 'proxima-nova-light';
    text-transform: uppercase;
    opacity: 0.7;
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    svg {
      margin: 25px auto;
      width: 15em;
    }
  }
`;

const StyledSocialWrapper = styled.div`
  justify-self: end;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;

  svg {
    width: 2rem;
    height: auto;
    fill: ${(props) => props.theme.colors.white};
    opacity: 0.5;

    &:hover {
      opacity: 1;
      fill: ${(props) => props.theme.colors.gold};
    }
  }

  @media (max-width: 600px) {
    margin: auto;
  }
`;

const SuperiorLink = styled.a`
  color: ${(props) => props.theme.colors.gold};
`

// component definition
const Footer = () => {
  return (
    <>
      <StyledFooter>
        <Logo />
        <p>
          Copyright ©2022 Superior Concrete Fence of Texas, Inc. Affiliated with{" "}
          <SuperiorLink 
            href="https://www.superiorengineeringusa.com/" 
            target='_blank'
            rel='noopener noreferrer'
          >
            Superior Engineering
          </SuperiorLink>
        </p>
        <StyledSocialWrapper>
          <a
            href='https://www.facebook.com/ConcreteFence35'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FacebookIcon />
          </a>
          <a
            href='https://www.instagram.com/superiorconcrete_official/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <InstagramIcon />
          </a>
          <a
            href='https://www.linkedin.com/company/superior-concrete-products-inc-/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkedInIcon />
          </a>
        </StyledSocialWrapper>
      </StyledFooter>
    </>
  );
};

export default Footer;
