import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    text-rendering: optimizeLegibility;
    font-size: 16px;
    line-height: 1.4;
    overflow-x: hidden;
  }

  body {
    font-size: 16px;
    line-height: var(--base-line-height);
    word-wrap: break-word;
    margin: 0;
    padding: 0;
  }

  main {
    z-index: 0;
    display: block;
  }

  button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 40px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:hover {
      filter: brightness(95%);
    }

    span {
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  hgroup {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
  }
  ul {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyles;
