import React from 'react';
import Layout from './src/components/shared/Layout';

// This function is duplicated in gatsby-ssr.js
export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript(
      'https://app.jazz.co/widgets/basic/create/SuperiorConcreteFenceofTexasInc'
    );
  };
};
