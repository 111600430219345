import { createGlobalStyle, css } from 'styled-components';

// woff
import proximaNovaLight from '../assets/fonts/proxima-nova-light.woff';
import proximaNovaRegular from '../assets/fonts/proxima-nova-regular.woff';
import proximaNovaRegularItalic from '../assets/fonts/proxima-nova-regular-italic.woff';
import proximaNovaSemiBold from '../assets/fonts/proxima-nova-semibold.woff';
import proximaNovaBold from '../assets/fonts/proxima-nova-bold.woff';
import proximaNovaBlack from '../assets/fonts/proxima-nova-black.woff';

// woff2
import proximaNovaLightTwo from '../assets/fonts/proxima-nova-light.woff2';
import proximaNovaRegularTwo from '../assets/fonts/proxima-nova-regular.woff2';
import proximaNovaRegularItalicTwo from '../assets/fonts/proxima-nova-regular-italic.woff2';
import proximaNovaSemiBoldTwo from '../assets/fonts/proxima-nova-semibold.woff2';
import proximaNovaBoldTwo from '../assets/fonts/proxima-nova-bold.woff2';
import proximaNovaBlackTwo from '../assets/fonts/proxima-nova-black.woff2';

const Typography = createGlobalStyle`${css`
  @font-face {
    font-family: 'proxima-nova-light';
    src: url(${proximaNovaLightTwo}) format('woff2'),
      url(${proximaNovaLight}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'proxima-nova-regular';
    src: url(${proximaNovaRegularTwo}) format('woff2'),
      url(${proximaNovaRegular}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'proxima-nova-regular-italic';
    src: url(${proximaNovaRegularItalicTwo}) format('woff2'),
      url(${proximaNovaRegularItalic}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'proxima-nova-semibold';
    src: url(${proximaNovaSemiBoldTwo}) format('woff2'),
      url(${proximaNovaSemiBold}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'proxima-nova-bold';
    src: url(${proximaNovaBoldTwo}) format('woff2'),
      url(${proximaNovaBold}) format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'proxima-nova-black';
    src: url(${proximaNovaBlackTwo}) format('woff2'),
      url(${proximaNovaBlack}) format('woff');
    font-display: fallback;
  }

  html {
    font-family: 'proxima-nova-regular', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    color: var(--darkGray);
  }
  p,
  li {
    letter-spacing: 0.5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-family: 'proxima-nova-black';
    font-size: 3rem;
    margin-bottom: 1.45rem;
    letter-spacing: 1.5px;

    @media (max-width: 600px) {
      font-size: 1.75rem;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'proxima-nova-bold';
    margin: 0;
  }

  h2 {
    font-size: 2.75rem;
  }
  h3 {
    font-size: 2rem;

    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  }
  h4 {
    font-size: 1.75rem;
  }
  h5 {
    font-size: 1.25rem;
    letter-spacing: 1px;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
  h6 {
    font-size: 1rem;
  }

  a {
    color: var(--dark-gray);
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: var(--scp-gold);
    color: var(--scp-gold);
  }
`}`;

export default Typography;
