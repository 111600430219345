// external dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

// local dependencies
import theme from '../../styles/theme';
import GlobalStyles from '../../styles/GlobalStyles';
import Typography from '../../styles/Typography';
import Nav from './Nav';
import Footer from './Footer';

// component definition
const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <ThemeProvider theme={theme}>
        <header>
          <Nav />
        </header>
        <div>
          <main>{children}</main>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
